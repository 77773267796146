import { AbstractControl, FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';


/**
 * Vlidate passwords equity
 */
export function confirmationPasswordIsEqualValidator(
  mainPwdControl: AbstractControl
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (mainPwdControl.value !== control.value) {
      return { confirmPwdValid: true };
    }
    return null;
  };
}

export function confirmationMailIsEqualValidator(
  emailKey: string,
  confirmeEmailKey: string
) {
  return (
    group: FormGroup
  ): {
    [key: string]: any;
  } => {
    const email = group.controls[emailKey];
    const confirmEmail = group.controls[confirmeEmailKey];

    if (email.value !== confirmEmail.value) {
      return { confirmMailValid: true };
    }
    return null;
  };
}

export class CustomValidators {
  static readonly DELIMITER = '-';

  public static parseDate(value) {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10),
      };
    }
    return null;
  }
}

/**
 * Custom validator without params
 */
export function isAdultValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  if (control.value) {
    const today = new Date();
    const formattedDate = CustomValidators.parseDate(control.value);

    let age = today.getFullYear() - formattedDate.year;
    if (formattedDate.year.toString().length !== 4) {
      return { invalidAge: true };
    }
    const m = today.getMonth() + 1 - formattedDate.month;

    if (m < 0 || (m === 0 && today.getDate() < formattedDate.day)) {
      age--;
    }
    if (age < 18) {
      return { invalidAge: true };
    }
    return null;
  }
}

/**
 * Custom validator for adress
 */
export function isAdressValid(city: string, postalCode: string) {
  if (city !== undefined && city !== '') {
    return postalCode !== undefined && postalCode !== '';
  } else {
    return postalCode === undefined || postalCode === '';
  }
}

// check if tow password is equal
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function atLeastOneFieldIsNotEmptyValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const email = control.get('email').value;
    const mobile = control.get('siret').value;

    if (!email && !mobile) {
      return { atLeastOne: true };
    }

    return null;
  };
}

/** 
 * Validator for phone number (using librairy libphonenumber-js)
 *
 * Values for param testPhoneType : 'FIXE', 'MOBILE', null (if you don't want to check it)
 * 
 * Errors that may be returned returns : 'format', 'type', 'region'
 * 
 * exemple valid num :
 * - Reunion fixed line : "+262 262 53 66 38"
 * - Reunion mobile : "+262 6 92 61 85 23"
 * - France mobile : "+33 6 18 11 11 11"
 * - France mobile without code : "06 18 11 11 11"
 */
export function validatePhoneNumber(testPhoneType : string | null): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

    const validationErrorsFormat = {format: true};
    const validationErrorsType = {type: true};
    const validationErrorsRegion = {region: true};

    const phone = control.value;

    if (!phone) {
      return null;
    }

    const phoneWithoutSpace = phone.replace(/ /g, '')

    const phoneNumber = parsePhoneNumberFromString(phoneWithoutSpace, 'FR');

    if (!phoneNumber || !phoneNumber.isValid()) {
      return validationErrorsFormat;
    }

    // Check type number
    if (phoneNumber.getType() && 
      ((testPhoneType === 'MOBILE' && (phoneNumber.getType() !== 'FIXED_LINE_OR_MOBILE' && phoneNumber.getType() !== 'MOBILE'))
      || (testPhoneType === 'FIXE' && (phoneNumber.getType() !== 'FIXED_LINE_OR_MOBILE' && phoneNumber.getType() !== 'FIXED_LINE')))) {
      return validationErrorsType;
    }

    // Check if it belongs to mainland France or DOM-TOM regions
    const validRegions = [
       // Metropolitan France
       'FR',
       // Belgium
       'BE',
       // DOM (Départements d'Outre-Mer)
       'GP', 'MQ', 'GF', 'RE', 'YT',
       // COM (Collectivités d'Outre-Mer)
       'PM', 'BL', 'MF', 'PF', 'WF', 'NC',
       // TAAF (Terres australes et antarctiques françaises)
       'TF'
    ];

    if (! validRegions.includes(phoneNumber.country)) {
      return validationErrorsRegion
    }

    return null;
  };
}
