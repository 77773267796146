<!-- HELLO -->
<div class="d-flex flex-column align-items-start justify-content-center mb-xxl-5 mb-xl-5 mb-lg-5 mb-md-3 mb-sm-3 mb-3">
  <span id="identity-title" class="d-flex flex-row align-items-start justify-content-center mb-2">
    {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.HELLO' | translate }}
  </span>
  <span id="identity-sub-title" class="d-flex flex-row align-items-start justify-content-center">
    {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.HELLO_DESCRIPTION' | translate }}
  </span>
</div>
<form class="w-100" name="form" role="form" [formGroup]="coordonneesForm" (submit)="nextStep()" (keydown.enter)="nextStep()">
  <div
    class="row mb-4"
  >
    <!-- CIVILITY -->
    <div class="col-lg-2 col-md-2 col-sm-12 mb-3">
      <label class="form-label" for="civility">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.CIVILITY' | translate }}</label>
      <select type="select" class="form-select form-control" name="civility" id="civility" [formControlName]="'civility'">
        <option value="MR">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.CIVILITY_M' | translate }} </option>
        <option value="MS">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.CIVILITY_MME' | translate }}</option>
      </select>
    </div>
    <!-- FIRST NAME -->
    <div class="col-lg-4 col-md-4 col-sm-12  mb-3">
      <label class="form-label" for="firstName">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.FIRST_NAME' | translate }}</label>
      <input
        required
        type="text"
        class="form-control"
        name="firstName"
        id="firstName"
        [formControlName]="'firstName'"
        maxlength="38"
        [ngClass]="{
          'is-invalid': coordonneesForm.get('firstName').invalid === true && coordonneesForm.get('firstName').touched === true,
          'is-valid': coordonneesForm.get('firstName').invalid === false && coordonneesForm.get('firstName').touched === true
        }"
        placeholder="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.FIRST_NAME_PROMPT' | translate }}"
      />
      <div *ngIf="coordonneesForm.get('firstName').touched === true && coordonneesForm.get('firstName').invalid">
        <small class="text-danger error-msg" *ngIf="coordonneesForm.get('firstName').errors.required">
          {{ 'COMMON.FORM.REQUIRED' | translate }}
        </small>
        <small class="text-danger error-msg" *ngIf="coordonneesForm.get('firstName').errors.pattern">
          {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.ERROR.FIRST_NAME_PATTERN' | translate }}
        </small>
      </div>
    </div>
    <!-- LAST NAME -->
    <div class="col-lg-4 col-md-4 col-sm-12  identity-input">
      <label class="form-label" for="lastName">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.LAST_NAME' | translate }}</label>
      <input
        required
        type="text"
        class="form-control"
        name="lastName"
        id="lastName"
        [formControlName]="'lastName'"
        maxlength="38"
        [ngClass]="{
          'is-invalid': coordonneesForm.get('lastName').invalid === true && coordonneesForm.get('lastName').touched === true,
          'is-valid': coordonneesForm.get('lastName').invalid === false && coordonneesForm.get('lastName').touched === true
        }"
        placeholder="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.LAST_NAME_PROMPT' | translate }}"
      />
      <div *ngIf="coordonneesForm.get('lastName').touched === true && coordonneesForm.get('lastName').invalid">
        <small
          class="text-danger error-msg"
          *ngIf="coordonneesForm.get('lastName').errors && coordonneesForm.get('lastName').errors.required"
        >
          {{ 'COMMON.FORM.REQUIRED' | translate }}
        </small>
        <small
          class="text-danger error-msg"
          *ngIf="coordonneesForm.get('lastName').errors && coordonneesForm.get('lastName').errors.pattern"
        >
          {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.ERROR.LAST_NAME_PATTERN' | translate }}
        </small>
      </div>
    </div>
  </div>
  <div
    class="row"
  >
    <!-- PHONE NUMBER -->
    <div class="col-lg-4 col-md-4 col-sm-12 mb-3">
      <label class="form-label" for="phoneNumber">{{
        'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.PHONE_NUMBER' | translate
      }}</label>
      <input
        type="tel"
        class="form-control"
        name="phoneNumber"
        id="phoneNumber"
        minlength="1"
        maxlength="20"
        [formControlName]="'phoneNumber'"
        [ngClass]="{
          'is-invalid':
            coordonneesForm.get('phoneNumber').invalid === true &&
            coordonneesForm.get('phoneNumber').touched === true &&
            coordonneesForm.get('phoneNumber').value,
          'is-valid':
            coordonneesForm.get('phoneNumber').invalid === false &&
            coordonneesForm.get('phoneNumber').touched === true &&
            coordonneesForm.get('phoneNumber').value
        }"
        placeholder="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.PHONE_NUMBER_PROMPT' | translate }}"
      />
      <div *ngIf="coordonneesForm.get('phoneNumber').touched === true && coordonneesForm.get('phoneNumber').invalid">
        <small class="text-danger error-msg" *ngIf="coordonneesForm.get('phoneNumber').errors.format">
          {{ 'COMMON.FORM.ERROR.PHONENUMBER.INVALID' | translate }}
        </small>
        <small class="text-danger error-msg" *ngIf="coordonneesForm.get('phoneNumber').errors.type">
          {{ 'COMMON.FORM.ERROR.PHONENUMBER.NOT_TYPE_FIXE' | translate }}
        </small>
        <small class="text-danger error-msg" *ngIf="coordonneesForm.get('phoneNumber').errors.region">
          {{ 'COMMON.FORM.ERROR.PHONENUMBER.REGION_NOT_SUPPORTED' | translate }}
        </small>
      </div>
    </div>
    <!-- CELL PHONE NUMBER -->
    <div class="col-lg-4 col-md-4 col-sm-12 phone-input">
      <label class="form-label" for="cellPhoneNumber">{{
        'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.CELL_PHONE_NUMBER' | translate
      }}</label>
      <input
        type="tel"
        class="form-control"
        name="cellPhoneNumber"
        id="cellPhoneNumber"
        minlength="1"
        maxlength="20"
        [formControlName]="'cellPhoneNumber'"
        [ngClass]="{
          'is-invalid':
            coordonneesForm.get('cellPhoneNumber').invalid === true &&
            coordonneesForm.get('cellPhoneNumber').touched === true &&
            coordonneesForm.get('cellPhoneNumber').value,
          'is-valid':
            coordonneesForm.get('cellPhoneNumber').invalid === false &&
            coordonneesForm.get('cellPhoneNumber').touched === true &&
            coordonneesForm.get('cellPhoneNumber').value
        }"
        placeholder="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.CELL_PHONE_NUMBER_PROMPT' | translate }}"
      />
      <div *ngIf="coordonneesForm.get('cellPhoneNumber').touched === true && coordonneesForm.get('cellPhoneNumber').invalid">
        <small class="text-danger error-msg" *ngIf="coordonneesForm.get('cellPhoneNumber').errors.format">
          {{ 'COMMON.FORM.ERROR.PHONENUMBER.INVALID' | translate }}
        </small>
        <small class="text-danger error-msg" *ngIf="coordonneesForm.get('cellPhoneNumber').errors.type">
          {{ 'COMMON.FORM.ERROR.PHONENUMBER.NOT_TYPE_MOBILE' | translate }}
        </small>
        <small class="text-danger error-msg" *ngIf="coordonneesForm.get('cellPhoneNumber').errors.region">
          {{ 'COMMON.FORM.ERROR.PHONENUMBER.REGION_NOT_SUPPORTED' | translate }}
        </small>
      </div>
    </div>
    <!-- ROLES -->
    <div class="col-lg-3 col-md-3 col-sm-12 role-input" *ngIf="withRoles === true">
      <label class="form-label" for="role">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.ROLE' | translate }}</label>
      <select type="select" class="form-select form-control" name="role" id="role" [formControlName]="'role'">
        <option value="Dirigeant">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.ROLE_DIRIGEANT' | translate }} </option>
        <option value="Comptable">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.ROLE_COMPTABLE' | translate }} </option>
        <option value="Proprietaire">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.ROLE_PROPRIETAIRE' | translate }} </option>
      </select>
      <div *ngIf="coordonneesForm.get('role').touched === true && coordonneesForm.get('role').invalid">
        <small
          class="text-danger error-msg"
          *ngIf="coordonneesForm.get('role').errors && coordonneesForm.get('role').errors.required"
        >
          {{ 'COMMON.FORM.REQUIRED' | translate }}
        </small>
      </div>
    </div>
  </div>
  <!-- SUBMIT -->
  <div class="d-flex flex-row align-items-center justify-content-start mt-4">
    <button
      type="button"
      class="btn btn-primary submit-btn"
      [disabled]="
        !coordonneesForm.valid ||
        isSubmitted ||
        isPhoneInvalid()
      "
      (click)="nextStep()"
    >
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div *ngIf="isSubmitted" class="spinner-border"></div>
        <span
          [ngClass]="{
            'ml-2': isSubmitted
          }"
          id="submit-btn-label"
          >{{ 'COMMON.FORM.CONTINUE' | translate }}</span
        >
      </div>
    </button>
  </div>
</form>
