import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import SecondStepApz from '../apz-second-step.class';
import {AbstractControl, FormBuilder, ValidationErrors, Validators} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SiloApiService } from '@services/siloApi.service';
import { GatewayService } from '@services/gateway.service';
import { Router } from '@angular/router';
import { TealiumService } from '@services/tealium/tealium.service';
import { emailRegEx } from '@shared/helpers/regexp.helpers';
import {
  confirmationMailIsEqualValidator,
  confirmationPasswordIsEqualValidator
} from '@shared/validators/custom.validators';
import { ModalService } from '@shared/components/modal/modal.service';
import { SecretQuestion } from '@shared/models/secret-question.model';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { BehaviorSubject } from 'rxjs';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import {finalize} from 'rxjs/operators';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

@Component({
  selector: 'app-second-step-bimpli-apetiz',
  templateUrl: './second-step-bimpli-apetiz.component.html',
  styleUrls: ['./second-step-bimpli-apetiz.component.scss']
})
export class SecondStepBimpliApetizComponent implements OnInit, DoCheck{
  @Input() saml: string;
  @Input() messageErreur: string;
  @Input() isLoading: boolean;
  @Input() application: string;
  @Output() submitted: EventEmitter<any> = new EventEmitter();

  isConfirmMailValid: boolean;
  private emailDirty = false;
  questions: Array<SecretQuestion>;
  alreadyUsedMail: boolean;
  private valueCaptcha: string;

  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isConfirmPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected fb: FormBuilder,
    protected translate: TranslateService,
    protected siloApiService: SiloApiService,
    protected gatewayService: GatewayService,
    private router: Router,
    private readonly modalService: ModalService,
    protected tealiumService: TealiumService,
    private readonly formBuilder: FormBuilder,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  emailFormControl = this.formBuilder.control('', [Validators.required, Validators.pattern(emailRegEx)]);
  passwordFormControl = this.formBuilder.control('', [Validators.required, Validators.minLength(8), Validators.maxLength(60)]);
  createAccountForm = this.formBuilder.group({
    login: this.emailFormControl,
    confirm_email: [{value: '', disabled: true}
      , [Validators.required,
        Validators.pattern(emailRegEx)
      ]],
    password: this.passwordFormControl,
    acceptCGU: [false, [Validators.requiredTrue]],
    phone: ['', [Validators.required, this.phoneValidator]],
    confirm_password: [{value: '', disabled: true},
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(60),
        confirmationPasswordIsEqualValidator(this.passwordFormControl)
      ]],
    secretQuestion: [undefined, [Validators.required]],
    response: [undefined, [Validators.required]]
  },
    { validator: confirmationMailIsEqualValidator('login', 'confirm_email')}
  );

  ngOnInit(): void {
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.application) {
      this.tealiumService.view('bimpli_benef.web.register.apetiz.identifiantPageLoad');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.application) {
      this.tealiumService.view('bimpli_mobile.web.register.apetiz.identifiantPageLoad');
    }

    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
        .execute('ReadSecretQuestionsAction')
        .pipe(
          finalize(() => {
            this.siloApiService.readSecretQuestions(authToken, this.valueCaptcha).subscribe((q) => (this.questions = q.items));
          })
        )
        .subscribe((token) => (this.valueCaptcha = token));
    });
  }

  // Custom validator using the current libphonenumber-js API
  phoneValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!value) {
      return null; // Let the required validator handle empty values
    }

    try {
      const validRegions = [
        // Metropolitan France
        'FR',
        // Belgium
        'BE',
        // DOM (Départements d'Outre-Mer)
        'GP', 'MQ', 'GF', 'RE', 'YT',
        // COM (Collectivités d'Outre-Mer)
        'PM', 'BL', 'MF', 'PF', 'WF', 'NC',
        // TAAF (Terres australes et antarctiques françaises)
        'TF'
      ];

      // Try to parse the number, defaulting to France
      const phoneNumber = parsePhoneNumberFromString(value, 'FR');
      const numberType = phoneNumber.getType();

      // Check if the phone number is valid and belongs to one of our valid regions
      if (phoneNumber && phoneNumber.isValid() && (numberType === 'MOBILE' || numberType === 'FIXED_LINE_OR_MOBILE')
        && validRegions.includes(phoneNumber.country)) {
        return null; // Valid number
      }

      return { invalidPhone: true };
    } catch (error) {
      return { invalidPhone: true };
    }
  }

  // Restrict input to numeric values, plus sign
  numericPhoneOnly(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/[^0-9+]/g, '');
  }

  getCGU() {
    // this.tealiumService.link('apz.web.registrationProcess.consultCGUClick');
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/cgu/' + this.saml])
    );

    window.open(url, '_blank');
  }

  isLessThanEightChars(p: string): boolean {
    return p.length < 8;
  }

  isMoreThanSixtyChars(p: string): boolean {
    return p.length > 60;
  }

  hasAtLeastOneLowerCase(p: string): boolean {
    return p.toUpperCase() !== p;
  }

  hasAtLeastOneUpperCase(p: string): boolean {
    return p.toLowerCase() !== p;
  }

  hasAtLeastOneDigit(p: string): boolean {
    return /\d/.test(p);
  }

  hasAtLeastOneSpecialChar(p: string): boolean {
    const pwdRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return pwdRegex.test(p);
  }

  isCorrectPwd(p: string): boolean {
    return !this.isLessThanEightChars(p) && !this.isMoreThanSixtyChars(p) && (
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneDigit(p)) ||
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneDigit(p) && this.hasAtLeastOneSpecialChar(p)) ||
      (this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneDigit(p) && this.hasAtLeastOneSpecialChar(p)) ||
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneSpecialChar(p))
    );
  }

  ngDoCheck(): void {

    this.isConfirmMailValid = (this.createAccountForm.get('confirm_email').dirty || this.createAccountForm.get('login').dirty) &&
      (this.createAccountForm.get('confirm_email').value.toUpperCase() === this.createAccountForm.get('login').value.toUpperCase());
    if (this.emailDirty !== this.createAccountForm.get('login').dirty) {
      this.emailDirty = this.createAccountForm.get('login').dirty;
      if (this.emailDirty) {
        this.createAccountForm.get('confirm_email').enable();
      }
    }
    if (this.createAccountForm.get('password').dirty) {
      this.createAccountForm.get('confirm_password').enable();
    }

    if (this.messageErreur && this.messageErreur !== '') {
      this.modalService.open('errorModal');
    }
  }

  validateForm() {
    // this.tealiumService.link('apz.web.registrationProcess.validateCreateAccountClick');
    localStorage.setItem('login', this.createAccountForm.get('login').value);
    this.submitForm();
  }

  submitForm() {
    if (this.createAccountForm.valid) {
      this.submitted.emit({ form: this.createAccountForm.getRawValue()});
    }
  }

  closePopin(id: string) {
    this.modalService.close(id);
    if (this.messageErreur && this.messageErreur === this.translate.instant('CREATION_ACCOUNT.SECOND_STEP.ERROR.UNEXPECTED')) {
      this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_CHOICE}/${this.saml}`);
    }
    this.messageErreur = '';
  }

  back(): void {
    window.history.back();
  }
}
