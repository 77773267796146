import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Coordonnee } from '@shared/models/coordonnee';
import { validatePhoneNumber } from '@shared/validators/custom.validators';

@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss']
})
export class IdentityComponent implements OnInit, OnChanges {
  @Input() parentForm: FormGroup;
  @Input() withRoles: boolean;
  @Input() initialData: Coordonnee;
  @Output() coodonneeValidated: EventEmitter<boolean> = new EventEmitter();

  public coordonneesForm: FormGroup;
  public isSubmitted = false;


  constructor(private readonly fb: FormBuilder) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.initialData?.currentValue && this.coordonneesForm) {
      this.coordonneesForm.patchValue(this.initialData);
      this.coordonneesForm.markAllAsTouched();
    }
  }


  ngOnInit(): void {
    this.coordonneesForm = this.fb.group({
      civility: new FormControl('MR'),
      firstName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z][a-zA-Z ]{1,38}$')]),
      lastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z][a-zA-Z ]{1,38}$')]),
      phoneNumber:new FormControl('', [validatePhoneNumber('FIXE')]),
      cellPhoneNumber: new FormControl('', [validatePhoneNumber('MOBILE')])
    });

    if (this.withRoles) {
      this.coordonneesForm.addControl('role', new FormControl('', Validators.required));
    }
    if (this.initialData) {
      this.coordonneesForm.patchValue(this.initialData);
    }

    if (this.parentForm) {
      this.parentForm.addControl('coordonnee', this.coordonneesForm);
      this.parentForm.updateValueAndValidity();
    }
  }

  public nextStep(): void {
    if (this.coordonneesForm.valid) {
      this.isSubmitted = true;
      this.coodonneeValidated.emit(true);
    }
  }

  public isPhoneInvalid(): boolean{
    if(this.coordonneesForm.get('role')?.value === 'Proprietaire' || this.coordonneesForm.get('role')?.value === 'Dirigeant' ){
      if( this.coordonneesForm.get('cellPhoneNumber')?.value ){
        return false;
      }
    }
     if(this.coordonneesForm.get('role')?.value === 'Comptable'){
      if(this.coordonneesForm.get('phoneNumber')?.value || this.coordonneesForm.get('cellPhoneNumber')?.value ){
        return  false;
      }
    }
    return true;
  }

}
